import { ResultOf, TypedDocumentNode, VariablesOf } from '@graphql-typed-document-node/core';
import { OperationDefinitionNode } from 'graphql';
import { GraphQLError, GraphQLResponse } from 'graphql-request/dist/types';
import { MaybeRefOrGetter, Ref, ref, toValue, watch } from 'vue';
import type { User } from '@/stores/UserStore';
import { useApiClient } from '@api-client/composables/useApiClient';
import { QueryObserverState, QueryObserverStateRefs, toSeparateRefs } from './helpers';

export type UseQueryContextOptions = {
    apiHost: MaybeRefOrGetter<string>
    user: MaybeRefOrGetter<Pick<User, 'username' | 'isAuthenticated'> | undefined>
}

type RequiredUseQueryOptions<Q extends TypedDocumentNode<any, any>> = {
    query: Q
} & (VariablesOf<Q> extends Record<string, never>
    // eslint-disable-next-line @typescript-eslint/ban-types
    ? {} : ({
        waitForVariablesToBeDefined?: false;
        variables: MaybeRefOrGetter<VariablesOf<Q>>
    } | {
        waitForVariablesToBeDefined: true;
        variables: MaybeRefOrGetter<VariablesOf<Q> | undefined>
    }))

type OptionalUseQueryOptions<Q extends TypedDocumentNode<any, any>> = {
    keepStaleResultWhileLoading: boolean;
    log: boolean;
    queryFunction: (
        apiHost: string,
        query: Q,
        variables: VariablesOf<Q>
    ) => Promise<{ data: ResultOf<Q>; errors?: GraphQLError[] }>
    flush: 'pre' | 'post' | 'sync';
}

export type CoreUseQueryOptions<Q extends TypedDocumentNode<any, any>> = RequiredUseQueryOptions<Q>
    & Partial<OptionalUseQueryOptions<Q>>;

export type UseQueryOptions<
    Q extends TypedDocumentNode<any, any>
> = UseQueryContextOptions & CoreUseQueryOptions<Q>;

// Helper that ensures that variables are defined to make types easier to work with
// in useQuery
function getUseQueryOptions<
    Q extends TypedDocumentNode<any, any>
>(options: UseQueryOptions<Q>) {
    const query = options.query;
    const variables = 'variables' in options ? options.variables : {} as VariablesOf<Q>;

    return {
        query,
        variables
    };
}

/**
 * Return an object with reactive properties that tracks the result
 * of querying the graphQL API.
 *
 * Usage:
 *
 * If you have a GQL query in a separate file
 * ```
 * query SomeQuery($param: String!) {
 *    getSomething {
 *        ...
 *    }
 * }
 * ```
 *
 * You can use this composable like:
 * ```
 * const {
 *   result,
 *   error,
 *   isLoading
 * } = useConfigurableQuery({
 *   apiHost: 'https://example.com/api/graphql',
 *   user: currentUser,
 *   query: SomeQuery,
 *   variables: { param: 'foo' }
 * );
 * ```
 *
 * The parameters and query properties can each be reactive if needed
 *
 * result, error, and isLoading are all refs that will automatically
 * be updated if the inputs change, or if the tenant API changes.
 *
 *
 * @param options.query A GraphQL query object
 * @param options.variables The variables required by the query. Can be omitted if the query doesn't require variables
 * @param options.apiHost URL to the API host to query against
 * @param options.user The currently signed-in user
 *
 * The following are all optional:
 * @param options.waitForVariablesToBeDefined If true, the query will not be run until the
 *                                            variables are defined
 *                                            Default: false
 * @param options.keepStaleResultWhileLoading If true, the result will not be cleared
 *                                            while the query is loading
 *                                            Default: true
 * @param options.queryFunction               A function that will be called to run the
 *                                            query. This is useful if you need to customize
 *                                            the query behaviour, such as for unit testing
 *
 * @returns A FetchStatus object whose keys are reactive
 */
export function useConfigurableQuery<
    Q extends TypedDocumentNode<any, any>
>(options: UseQueryOptions<Q>): QueryObserverStateRefs<ResultOf<Q>> & { invalidate: () => void } {
    const { apiClient } = useApiClient();
    const {
        query,
        variables
    } = getUseQueryOptions(options);
    const keepStaleResultWhileLoading = options.keepStaleResultWhileLoading ?? true;

    const queryName = (query.definitions?.[0] as OperationDefinitionNode)?.name?.value;

    const statusRef: Ref<QueryObserverState<ResultOf<Q>>> = ref({
        isLoading: true
    });

    const queryFunction = options.queryFunction
        ?? ((apiHost, query, variables) => apiClient.value.querySingleRegion(apiHost, query, variables));

    let queryCount = 0;
    const invalidateCount = ref(0);

    if (options.log) {
        console.log(`useQuery ${queryName} start`);
    }

    watch([
        () => toValue(options.user),
        () => query,
        () => toValue(variables),
        () => toValue(options.apiHost),
        () => invalidateCount.value
    ], async ([user, q, v, apiHost, invalidateCount], [prevUser, , , , prevInvalidateCount]) => {
        try {
            // If we don't have an authenticated user, clear the results and return
            if (!user || !user.isAuthenticated) {
                statusRef.value = {
                    isLoading: true,
                    result: undefined
                };
                if (options.log) {
                    console.log(`useQuery ${queryName} user:`, user);
                    console.log(`useQuery ${queryName} exit early due to missing user`);
                }
                return;
            }

            const currentQuery = ++queryCount;

            // If the authenticated user doesn't match what was here before, clear the
            // results, but we can still run the query.
            const keepPreviousResult = keepStaleResultWhileLoading
                && user.username === prevUser?.username
                && invalidateCount === prevInvalidateCount;
            statusRef.value = {
                result: keepPreviousResult
                    ? statusRef.value.result
                    : undefined,
                isLoading: true,
            };
            if (!apiHost) {
                if (options.log) {
                    console.log(`useQuery ${queryName} exit early due to missing apiHost`);
                }
                return;
            }
            if (!v) {
                if (options.log) {
                    console.log(`useQuery ${queryName} exit early due to missing variables`);
                }
                return;
            }

            const { data, errors } = await queryFunction(apiHost, q, v);
            if (!data) {
                throw new Error('No data returned from query');
            }

            if (options.log) {
                console.log(`useQuery ${queryName} result`, { data, errors });
            }

            if (queryCount !== currentQuery) {
                console.log(`useQuery ${queryName} a new query has been started, ignoring result`);
                return;
            }

            statusRef.value = {
                result: data,
                errors,
                isLoading: false
            };
        } catch (err) {
            if (options.log) {
                console.log(`useQuery ${queryName} error`, err);
            }
            statusRef.value = {
                errors: [err],
                isLoading: false
            };
        }
    }, { immediate: true, flush: options.flush });

    function invalidate() {
        invalidateCount.value++;
    }

    return {
        ...toSeparateRefs(statusRef),
        invalidate
    };
}
