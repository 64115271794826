<template>
    <div
        ref="mapRoot"
        class="map-root flex relative w-full h-full min-w-80 min-h-80"
        @mouseout="onMapMouseout"
    >
        <div
            ref="mapContainer"
            class="map-container inline-block w-full h-full"
            :class="`${tooltipStyle === 'riskApp' ? 'risk-app-style' : ''}`"
            :style="{
                opacity: `${loaded ? 1 : 0}`,
                pointerEvents: `${loaded ? 'all' : 'none'}`,
            }"
        />
        <div
            ref="tooltipEl"
            class="tooltip-content pointer-events-none"
        >
            <slot
                v-if="tooltipFeature"
                name="tooltip-content"
                v-bind="tooltipFeature.properties"
            />
        </div>
        <div
            ref="popup"
            class="popup-content"
        >
            <slot
                v-if="popupFeature"
                name="popup-content"
                v-bind="popupProperties"
            />
        </div>
        <slot
            v-if="!loaded || forceLoadSpinner"
            name="loading-overlay"
        >
            <loading-overlay />
        </slot>
    </div>
</template>

<style lang="postcss" scoped>
canvas {
    outline: none;
}

.mapboxgl-popup-content {
    padding: 0 !important;
}

.map-container :deep(.mapboxgl-popup.tooltip) {
    pointer-events: none !important;
}

.map-container :deep(.mapboxgl-popup.tooltip .mapboxgl-popup-content) {
    pointer-events: none !important;
}

.map-container.risk-app-style :deep(.mapboxgl-popup.tooltip .mapboxgl-popup-content) {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.map-container :deep(.mapboxgl-popup-close-button) {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

/*
 * Setting a dynamic .transparent-tooltip class based on the transparentTooltip prop was causing
 * the div.map-container to lose its .mapboxgl-map class for some reason (which is required for
 * proper Mapbox fonts/styling). Instead, the opacity of the tooltip is controlled using this
 * v-bind computed property which avoids setting a class dynamically on the element.
 */
.map-container :deep(.tooltip) {
    transition: opacity 150ms ease;
    opacity: v-bind(tooltipOpacity);
}
</style>

<script setup lang="ts">
import { promiseTimeout, until, useResizeObserver } from '@vueuse/core';
import type { Feature, MultiPolygon, Polygon } from 'geojson';
import _ from 'lodash';
import mapboxgl, { LngLatBounds, LngLatBoundsLike, MapMouseEvent } from 'mapbox-gl';
import {
    computed,
    markRaw,
    nextTick,
    onBeforeUnmount,
    onErrorCaptured,
    onMounted,
    ref,
    shallowRef,
    watch,
} from 'vue';
import type { Ref } from 'vue';
import LoadingOverlay from '@component-library/components/LoadingOverlay/LoadingOverlay.vue';
import { decodeImageUri } from '@component-library/helpers';
import { MapPosition } from '@deployment-client/Main.vue';
import { BearingButton } from './controls/BearingButton';
import type { MapLayer, MapSource, MapVectorSource } from './types';
import {
    getAnchor,
    getBbox,
    getClosestPointBiasedNorth,
    updateLayer as updateMapLayer,
    updateSource as updateMapSource,
} from './util';

const ICONS: Record<string, any> = (import.meta as any).glob(
    '@component-library/assets/images/icons/*.(png|jpe?g)',
    { eager: true },
);

type SourceId = string;
type VectorSourceIdAndSourceLayer = { sourceId: SourceId; sourceLayer: string };
type HoveredFeatureIdBySource = Map<
    SourceId | VectorSourceIdAndSourceLayer,
    string | number | undefined
>;

const props = withDefaults(defineProps<{
    accessToken: string;
    styleUrl: string;
    layers?: MapLayer[];
    sources?: (MapSource | MapVectorSource)[];
    tooltip?: {
        feature?: Feature;
        closeButton?: boolean;
        // Tooltip anchor must remain on-screen
        // within these margins
        constrainAnchorPosition?: {
            top?: number;
            left?: number;
            right?: number;
        }
    } | null;
    tooltipStyle?: 'riskApp' | 'legacy';
    popupFeature?: Feature<MultiPolygon | Polygon> | null;
    maxBounds?: LngLatBoundsLike | null;
    focusedBounds?: LngLatBoundsLike | null;
    lastPos?: MapPosition | null,
    maxZoom?: number;
    initialBearing?: number;
    forceLoadSpinner?: boolean;
    hoveredFeatureIdBySourceId?: HoveredFeatureIdBySource;
    // Settng to null will disable the navControlPosition
    // Leaving it blank/ undefined will default to 'bottom-right'
    navControlPosition?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | null;
    transparentTooltip?: boolean;
    interactive?: boolean;
    doubleClickZoomEnabled?: boolean;
    transformMapboxRequest?: mapboxgl.MapboxOptions['transformRequest'] | null;
}>(), {
    layers: () => [],
    sources: () => [],
    tooltip: null,
    tooltipStyle: 'legacy',
    popupFeature: null,
    maxBounds: null,
    focusedBounds: null,
    maxZoom: 15,
    initialBearing: 0,
    forceLoadSpinner: false,
    hoveredFeatureIdBySourceId: () => new Map(),
    navControlPosition: 'bottom-right',
    transparentTooltip: false,
    interactive: true,
    doubleClickZoomEnabled: true,
    lastPos: null,
    transformMapboxRequest: null,
});

const emit = defineEmits<{
    (e: 'mapbox-loaded', payload: mapboxgl.Map): void,
    (e: 'click', payload: mapboxgl.MapboxGeoJSONFeature[]): void,
    (e: 'dblclick', payload: mapboxgl.MapboxGeoJSONFeature[]): void,
    (e: 'mousemove', payload: mapboxgl.MapboxGeoJSONFeature[]): void,
    (e: 'zoomend', payload: number): void,
    (e: 'mouseout', payload: MouseEvent): void,
    (e: 'tooltip-close'): void
    (e: 'wheel'): void
    (e: 'drag'): void
}>();

const mapRoot = ref<HTMLDivElement | null>(null);
const mapContainer = ref<HTMLDivElement | null>(null);
const tooltipEl = ref<HTMLDivElement | null>(null);
const popup = ref<HTMLDivElement | null>(null);

const loaded = ref(false);
const defaultCenter = ref<any>(null);
const defaultZoom = ref(12);
const tooltipFeature = ref<any>(null);
const tooltipObject = ref<mapboxgl.Popup>(null as any);
const popupObject = ref<mapboxgl.Popup>(null as any);
// When hoveredFeatureIdBySourceId is not provided, the internalHoveredFeatureIdBySource object will track
// any hovered feature IDs by source IDs where the source has the mousemove event set to true.
const internalHoveredFeatureIdBySource: Ref<HoveredFeatureIdBySource> = ref(new Map());

// eslint-disable-next-line
mapboxgl.accessToken = props.accessToken;

const map = shallowRef<mapboxgl.Map>(null as any);

const allHoveredFeatureIdBySource = computed<HoveredFeatureIdBySource>(() => new Map([
    ...internalHoveredFeatureIdBySource.value.entries(),
    ...props.hoveredFeatureIdBySourceId.entries(),
]));

const popupProperties = computed(() => ({
    close: () => {
        popupObject.value.remove();
    },
    ...(props.popupFeature ? props.popupFeature.properties : {})
}));

const clickLayers = computed(() => props.layers
    .filter(layer => layer.events && layer.events.click)
    .map(layer => layer.id));

const dblclickLayers = computed(() => props.layers
    .filter(layer => layer.events && layer.events.dblclick)
    .map(layer => layer.id));

const layersClickable = computed(() => clickLayers.value.every((layerId) => !!map.value.getLayer(layerId)));

const mousemoveLayers = computed(() => props.layers
    .filter(layer => layer.events && layer.events.mousemove)
    .map(layer => layer.id));

const tooltipOpacity = computed(() => ((props.transparentTooltip) ? 0.25 : 1));

const isMounted = ref(false);

onMounted(() => {
    // Mark map as raw to prevent Vue reactivity from deeply
    // inspecting the mapboxgl map properties
    map.value = markRaw(new mapboxgl.Map({
        container: mapContainer.value as HTMLDivElement,
        style: props.styleUrl,
        bearing: props.initialBearing,
        maxZoom: props.maxZoom,
        interactive: props.interactive,
        dragRotate: false,
        doubleClickZoom: props.doubleClickZoomEnabled,
        transformRequest: props.transformMapboxRequest || undefined,
    }));

    if (props.interactive && props.navControlPosition) {
        map.value.addControl(
            new mapboxgl.NavigationControl({ showCompass: false }),
            props.navControlPosition,
        );
    }

    tooltipObject.value = new mapboxgl.Popup({
        closeOnClick: false,
        closeButton: false,
        className: 'tooltip',
        anchor: 'bottom'
    });
    tooltipObject.value.setDOMContent(tooltipEl.value as HTMLDivElement);
    popupObject.value = new mapboxgl.Popup({
        closeOnClick: false,
        closeButton: false,
        className: 'popup',
        anchor: 'left'
    });
    popupObject.value.setDOMContent(popup.value as HTMLDivElement);

    map.value.on('load', () => {
        initialRender();
        emit('mapbox-loaded', map.value);

        if (props.initialBearing) {
            map.value.addControl(
                new BearingButton(() => {
                    const bearing = (map.value.getBearing() === 0)
                        ? props.initialBearing
                        : 0;
                    map.value.setBearing(bearing);
                    setBearingButtonIconRotation(bearing);
                }),
                props.navControlPosition as 'bottom-right' | 'top-left' | 'top-right' | 'bottom-left',
            );
            // Set initial rotation of bearing button icon
            setBearingButtonIconRotation(props.initialBearing);
        }
    });

    const throttledMousemoveHandler = _.throttle((event) => onMapMousemove(event), 1000 / 30);

    map.value.on('mousemove', throttledMousemoveHandler);

    map.value.on('mouseout', () => {
        throttledMousemoveHandler.cancel();
        internalHoveredFeatureIdBySource.value = new Map();
        emit('mousemove', []);
    });

    let doubleClickThresholdTimer: ReturnType<typeof setTimeout> | undefined;
    let numClicksWithinThreshold = 0;

    map.value.on('click', (event: MapMouseEvent) => {
        const singleClickFeatures = map.value.queryRenderedFeatures(event.point, { layers: clickLayers.value });
        const doubleClickFeatures = map.value.queryRenderedFeatures(event.point, { layers: dblclickLayers.value });

        if (dblclickLayers.value.length === 0) {
            // Don't use our fancy double click detection logic until we're sure
            // there are no impacts on SOC apps
            emit('click', singleClickFeatures);
            return;
        }

        // When user clicks it may be a single click or the first of a double click.
        // Always emit the first click right away since delaying to see if it's part
        // of a double click makes the map feel unresponsive.
        //
        // When a second click is received:
        // - if it's within the threshold, emit a double click rather than a second single click
        //   (so for a double click the events emitted are: click, dblclick)
        // - if it's not within the threshold (i.e. a second single click), emit the second single click
        numClicksWithinThreshold += 1;

        if (numClicksWithinThreshold === 1) {
            emit('click', singleClickFeatures);
            doubleClickThresholdTimer = setTimeout(() => {
                numClicksWithinThreshold = 0;
            }, 250);
        } else {
            emit('dblclick', doubleClickFeatures);
            clearTimeout(doubleClickThresholdTimer);
            numClicksWithinThreshold = 0;
        }
    });
    map.value.on('zoomend', () => {
        emit('zoomend', map.value.getZoom());
    });
    map.value.on('wheel', () => {
        emit('wheel');
    });
    map.value.on('drag', () => {
        emit('drag');
    });

    const moveHandler = () => positionTooltip();

    map.value.on('move', _.throttle(moveHandler, 1000 / 60));

    map.value.resize();

    isMounted.value = true;
});

useResizeObserver(
    mapRoot,
    () => {
        map.value?.resize();
    }
);

onBeforeUnmount(() => {
    if (map.value) {
        map.value.remove();
    }
});

onErrorCaptured((error) => {
    console.log('Map component errorCaptured');
    console.error(error);
    return false;
});

function setFeatureHoverState(
    source: VectorSourceIdAndSourceLayer | SourceId,
    featureId: string | number | undefined,
    newHoverState: boolean,
) {
    const newState = { hover: newHoverState };
    if (typeof source === 'string') {
        map.value.setFeatureState({ source, id: featureId }, newState);
    } else {
        map.value.setFeatureState({
            source: source.sourceId,
            sourceLayer: source.sourceLayer,
            id: featureId,
        }, newState);
    }
}

function getFeatureIdFromSource(
    featureIdBySourceId: HoveredFeatureIdBySource,
    source: VectorSourceIdAndSourceLayer | SourceId
) {
    if (typeof source === 'string') {
        return featureIdBySourceId.get(source);
    }

    let featureId: string | number | undefined;

    if (typeof source !== 'string') {
        // We need to use this approach rather than just featureIdBySourceId.get()
        // because that method relies on object identity (and thus always return
        // undefined) rather than object equivalence
        featureIdBySourceId.forEach((hoveredFeatureId, sourceIdAndLayer) => {
            if (typeof sourceIdAndLayer !== 'string') {
                if (
                    sourceIdAndLayer.sourceId === source.sourceId
                    && sourceIdAndLayer.sourceLayer === source.sourceLayer
                ) {
                    featureId = hoveredFeatureId;
                }
            }
        });
    }

    return featureId;
}

watch(allHoveredFeatureIdBySource, (hoveredFeatureIdBySource, prevHoveredFeatureIdBySource) => {
    if (!loaded.value) {
        return;
    }

    if (prevHoveredFeatureIdBySource.size === 0) {
        // On first load, if we have a hovered feature set the hover state
        hoveredFeatureIdBySource.forEach((featureId, source) => {
            if (featureId !== undefined) {
                setFeatureHoverState(source, featureId, true);
            }
        });
    } else {
        const currentAndPrevHoveredSources = new Set([
            ...hoveredFeatureIdBySource.keys(),
            ...prevHoveredFeatureIdBySource.keys(),
        ]);

        currentAndPrevHoveredSources.forEach((source) => {
            // Set the hover state to false (if exists) for previous feature
            // Set the hover state to true (if exists) for current feature

            const currentHoveredFeatureId = getFeatureIdFromSource(
                hoveredFeatureIdBySource,
                source,
            );

            const prevHoveredFeatureId = getFeatureIdFromSource(
                prevHoveredFeatureIdBySource,
                source,
            );

            if (currentHoveredFeatureId !== prevHoveredFeatureId) {
                if (prevHoveredFeatureId !== undefined) {
                    setFeatureHoverState(source, prevHoveredFeatureId, false);
                }
                if (currentHoveredFeatureId !== undefined) {
                    setFeatureHoverState(source, currentHoveredFeatureId, true);
                }
            }
        });
    }
});

watch(() => props.layers, (value, prevValue) => {
    if (!loaded.value) {
        initialRender();
        return;
    }

    _(value).difference(prevValue).forEach(layer => updateLayer(layer));
});

watch(() => props.sources, (value, prevValue) => {
    if (!loaded.value) {
        initialRender();
        return;
    }

    _(value).difference(prevValue).forEach(source => updateSource(source));
});

watch(() => props.maxBounds, (value) => {
    if (!loaded.value || value === null) {
        return;
    }

    updateMaxBounds(value);
});

watch(() => props.focusedBounds, (value) => {
    if (!loaded.value || value === null) {
        return;
    }

    jumpToBounds(value);
});

watch(() => props.tooltip, (value, prevValue) => {
    if (value && value.feature) {
        tooltipFeature.value = value.feature;
        const sameFeature = value.feature?.properties?.id === prevValue?.feature?.properties?.id
            && value.closeButton === prevValue?.closeButton;

        if (!sameFeature && tooltipObject.value) {
            tooltipObject.value.off('close', closeTooltip);
            tooltipObject.value.remove();
            tooltipObject.value = new mapboxgl.Popup({
                closeOnClick: false,
                closeButton: !!value.closeButton,
                className: 'tooltip',
                offset: tooltipFeature.value.properties.radius
                    ? [0, -tooltipFeature.value.properties.radius]
                    : undefined
            });
            tooltipObject.value.addTo(map.value);
            tooltipObject.value.on('close', closeTooltip);
        }
        tooltipObject.value?.setDOMContent(tooltipEl.value as HTMLDivElement);
        positionTooltip();
    } else {
        tooltipFeature.value = null;
        tooltipObject.value?.remove();
    }
});

watch(() => props.popupFeature, (feature) => {
    if (feature) {
        const coords = feature.geometry.type === 'MultiPolygon'
            ? _(feature.geometry.coordinates).flatten().flatten().value()
            : _(feature.geometry.coordinates).flatten().value();

        popupObject.value.addTo(map.value);
        popupObject.value.setLngLat(getAnchor(coords, 'east'));
    } else {
        popupObject.value.remove();
    }
});

watch(() => props.styleUrl, (value) => {
    map.value.setStyle(value);

    map.value.on('render', async () => {
        if (!map.value.isStyleLoaded()) {
            return;
        }

        props.sources.forEach(source => updateSource(source));
        props.layers.forEach(layer => updateLayer(layer));

        loadIcons();
    });
});

watch(() => props.lastPos, () => {
    if (!props.lastPos) return;

    map.value.setPadding(props.lastPos.padding);
    map.value.setCenter(props.lastPos.center);
    map.value.setBearing(props.lastPos.bearing);
    map.value.setZoom(props.lastPos.zoom);
}, { immediate: true });

function updateLayer(layer: mapboxgl.Layer) {
    updateMapLayer(map.value, layer);
}
function updateSource(source: MapSource | MapVectorSource) {
    if (!source) {
        return;
    }
    updateMapSource(map.value, source);
}
function updateMaxBounds(maxBounds: LngLatBoundsLike) {
    // If maxBounds is undefined, reset to no bounds
    if (maxBounds instanceof LngLatBounds && maxBounds.isEmpty()) {
        map.value.setMaxBounds(undefined);
        return;
    }
    const camera = map.value.cameraForBounds(maxBounds);
    if (camera) {
        const { center, zoom } = camera;
        // Clear the max bounds before attempting to jump so there are no restrictions
        map.value.setMaxBounds(undefined);
        // NOTE: Mapbox methods like setMaxBounds() and setMinZoom() can restrict the movement
        // of jumpTo(), creating incorrect bounds
        map.value.jumpTo({
            center,
            zoom: zoom - 0.25,
        });
        defaultCenter.value = map.value.getCenter();
        defaultZoom.value = map.value.getZoom();
    }

    const newBounds = map.value.getBounds();
    const height = newBounds.getNorth() - newBounds.getSouth();
    const width = newBounds.getEast() - newBounds.getWest();
    map.value.setMaxBounds([
        newBounds.getWest() - width / 2,
        newBounds.getSouth() - height / 2,
        newBounds.getEast() + width / 2,
        newBounds.getNorth() + height / 2
    ]);
}
function onMapMouseout(event: MouseEvent) {
    emit('mouseout', event);
}
function onMapMousemove(event: MapMouseEvent) {
    // Figure out if we should apply a clickable style to the map
    if (layersClickable.value) {
        const clickFeatures = map.value.queryRenderedFeatures(event.point, { layers: clickLayers.value });
        const canvasContainer = mapRoot.value?.querySelector('.mapboxgl-canvas-container');
        if (canvasContainer) {
            canvasContainer.classList.toggle('clickable', clickFeatures.length > 0);
        }
    }

    const featuresUnderMouse = map.value.queryRenderedFeatures(event.point, { layers: mousemoveLayers.value });

    const featuresUnderMouseAlsoInProps = new Map();
    featuresUnderMouse.filter((feature) => {
        if (feature.sourceLayer) {
            return getFeatureIdFromSource(
                props.hoveredFeatureIdBySourceId,
                { sourceId: feature.source, sourceLayer: feature.sourceLayer }
            ) !== undefined;
        }

        return getFeatureIdFromSource(props.hoveredFeatureIdBySourceId, feature.source) !== undefined;
    }).forEach((f) => {
        if (f.sourceLayer) {
            featuresUnderMouseAlsoInProps.set({ sourceId: f.source, sourceLayer: f.sourceLayer }, f.id);
        } else {
            featuresUnderMouseAlsoInProps.set(f.source, f.id);
        }
    });

    if (!_.isEqual(internalHoveredFeatureIdBySource.value, featuresUnderMouseAlsoInProps)) {
        internalHoveredFeatureIdBySource.value = featuresUnderMouseAlsoInProps;
    }

    // Emit a single event for all hovered layers so we can handle combinations of hovered layers
    emit('mousemove', featuresUnderMouse);
}
function zoomTo(feature: Feature<MultiPolygon | Polygon, unknown>) {
    if (!feature) {
        map.value.easeTo({
            zoom: defaultZoom.value,
            center: defaultCenter.value
        });
    } else {
        const coords = feature.geometry.type === 'MultiPolygon'
            ? _.flatten(feature.geometry.coordinates.map((c) => c[0]))
            : feature.geometry.coordinates[0];

        const camera = map.value.cameraForBounds(getBbox(coords));
        if (camera) {
            const { center, zoom } = camera;
            map.value.easeTo({ center, zoom: Math.min(zoom - 0.5, 13) });
        }
    }
}
function jumpToBounds(bounds: LngLatBoundsLike, options?: Pick<mapboxgl.CameraForBoundsOptions, 'padding'>) {
    const camera = map.value.cameraForBounds(bounds, { padding: options?.padding });
    if (camera) {
        map.value.jumpTo({
            zoom: camera.zoom,
            center: camera.center,
        });
    }
}
function zoomToBounds(bounds: LngLatBoundsLike, options: mapboxgl.CameraForBoundsOptions = { padding: 20 }) {
    const camera = map.value.cameraForBounds(bounds, options);
    if (camera) {
        map.value.easeTo({
            zoom: camera.zoom,
            center: camera.center
        });
    }
}
function moveLayer(id: string, beforeId?: string) {
    map.value?.moveLayer(id, beforeId);
}
function moveLayerToFront(id: string) {
    map.value?.moveLayer(id);
}
function moveLayerToBack(id: string) {
    const beforeId = props.layers.find((layer) => layer.id !== id)?.id;
    if (beforeId) {
        map.value?.moveLayer(id, beforeId);
    }
}
async function generateImageUri(mimeType = 'image/png'): Promise<string> {
    // Wait for map to be fully ready and rendered
    await until(isMounted).toBe(true);
    await nextTick();
    await waitForMapToRender();

    // Generate the image
    const canvas = map.value.getCanvas();
    const imageUri = canvas
        .toDataURL(mimeType)
        .replace(mimeType, 'image/octet-stream');
    return imageUri;
}
async function generateImage(mimeType = 'image/png'): Promise<HTMLImageElement> {
    const imageUri = await generateImageUri(mimeType);
    return decodeImageUri(imageUri);
}
async function downloadAsImage(imageTitle = 'Map.png', mimeType = 'image/png') {
    const imageUri = await generateImageUri(mimeType);
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUri;
    downloadLink.download = imageTitle;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

function setBearingButtonIconRotation(bearing: number) {
    const bearingIcon = document.querySelector('.mapboxgl-ctrl-icon.mapbox-ctrl-bearing img');
    if (bearingIcon) {
        (bearingIcon as HTMLImageElement).style.transform = `rotate(${-bearing}deg)`;
    }
}

async function waitForMapToRender() {
    await until(isMounted).toBe(true);
    return Promise.race([
        promiseTimeout(5000),
        new Promise<void>((resolve) => {
            map.value.once('render', () => {
                if (
                    !map.value.loaded()
                    || !map.value.areTilesLoaded()
                    || map.value.isMoving()
                    || map.value.isZooming()
                ) {
                    map.value.once('idle', resolve);
                } else {
                    resolve();
                }
            });
            map.value.triggerRepaint();
        })
    ]);
}
function loadIcons() {
    Object.entries(ICONS).forEach(([filepath, module]) => {
        // Remove path and extension
        const id = filepath.replace(/^.*[\\/]/, '').replace(/\.[^/.]+$/, '');
        const image = new Image();
        image.src = module.default;
        image.onload = () => {
            if (!map.value.hasImage(id)) {
                map.value.addImage(id, image);
            }
        };
    });
}
function positionTooltip() {
    if (!tooltipFeature.value) {
        return;
    }
    const geom = tooltipFeature.value.geometry;
    const allFeatureLngLats = (geom.type === 'Point'
        ? [geom.coordinates]
        : (geom.type === 'MultiPolygon'
            ? _(geom.coordinates).flatten().flatten().value()
            : _(geom.coordinates).flatten().value())) as [number, number][];

    /*
        Set the anchor to the north of the sector
        if north is not visible, move anchor to east of the sector
        if east is not visible, move anchor to west of the sector

        bias skews the result towards points that are more northern so that the anchor
        is less likely to obstruct stations if the anchor is set too southernly
    */
    let [tooltipX, tooltipY] = getClosestPointBiasedNorth(
        map.value.project(getAnchor(allFeatureLngLats)),
        allFeatureLngLats.map((lngLat) => map.value.project(lngLat)),
        1000
    );
    // @ts-ignore
    tooltipObject.value.options.anchor = 'bottom';

    const {
        width: tooltipWidth,
        height: tooltipHeight,
    } = tooltipObject.value.getElement()?.getBoundingClientRect() ?? {
        width: 100,
        height: 100,
    };

    const { width: mapWidth } = map.value.getContainer().getBoundingClientRect();

    // Pixel positions are relative to the container, so top-left = (0, 0)
    // regardless of where the map is positioned
    const topPx = 0 + (props.tooltip?.constrainAnchorPosition?.top ?? 0);
    const leftPx = 0 + (props.tooltip?.constrainAnchorPosition?.left ?? 0);
    const rightPx = 0 + mapWidth - (props.tooltip?.constrainAnchorPosition?.right ?? 0);

    if (tooltipY - tooltipHeight < topPx) {
        // @ts-ignore
        tooltipObject.value.options.anchor = 'top';
    }
    if (tooltipY < topPx) {
        tooltipY = topPx;
    }

    if (tooltipX - tooltipWidth / 2 < leftPx) {
        // @ts-ignore
        tooltipObject.value.options.anchor += '-left';
    } else if (tooltipX + tooltipWidth / 2 > rightPx) {
        // @ts-ignore
        tooltipObject.value.options.anchor += '-right';
    }
    if (tooltipX < leftPx) {
        tooltipX = leftPx;
    } else if (tooltipX > rightPx) {
        tooltipX = rightPx;
    }

    const {
        lng: tooltipLng,
        lat: tooltipLat,
    } = map.value.unproject([tooltipX, tooltipY]);
    tooltipObject.value.setLngLat([tooltipLng, tooltipLat]);
}
function closeTooltip() {
    emit('tooltip-close');
}
function initialRender() {
    if (!map.value || !map.value.loaded() || !map.value.isStyleLoaded()) {
        return;
    }

    if (loaded.value || !props.sources || !props.layers) {
        return;
    }

    props.sources.forEach(source => updateSource(source));
    props.layers.forEach(layer => updateLayer(layer));

    loadIcons();

    if (props.maxBounds) {
        updateMaxBounds(props.maxBounds);
    }

    if (props.focusedBounds) {
        jumpToBounds(props.focusedBounds, { padding: 20 });
    }

    loaded.value = true;
}

defineExpose({
    map,
    loaded,
    zoomTo,
    jumpToBounds,
    zoomToBounds,
    generateImageUri,
    generateImage,
    downloadAsImage,
    moveLayer,
    moveLayerToFront,
    moveLayerToBack,
    waitForMapToRender,
});
</script>
