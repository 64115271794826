import { TypeOf, compile, v } from 'suretype';

const riskSortedParcelList = v.array(
    v.object({
        taxParcelNumber: v.string().required(),
        adjustedParcelScore: v.number().integer().required(),
        streetAddress: v.string().required(),
    })
);

export const validateRiskSortedParcelList = compile(riskSortedParcelList, { ensure: true });
export type RiskSortedParcelList = TypeOf<typeof riskSortedParcelList>;
